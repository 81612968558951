import React from 'react'
import {Helmet} from 'react-helmet'
import './css/Title.css'

export default class Title extends React.Component {
	render() {
		return (
			<div id="pagetitle">
				<h1>{this.props.children}</h1>
				<Helmet>
					<title>{this.props.children} - Kisaro.de</title>
				</Helmet>
			</div>
		)
	}
}