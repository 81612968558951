import React from 'react'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet'
import Typing from 'react-typing-animation'

import './../css/Home.css'

export default () => (
	<div id="terminal" className="">
		<Helmet>
			<title>Hello World - Kisaro.de</title>
		</Helmet>
		<div style={{
			maxWidth: "1280px",
			margin: "0 auto"
		}}>
			<Typing speed={5}>
				<div className="meta">-- Receiving HTTP GET</div>
				<div className="meta">-- Loading HTML</div>
				<div className="meta">-- Loading CSS</div>
				<div className="meta">-- Loading JS bundles</div>
				<div className="meta">-- Hydrating the site</div>
				<Typing.Speed ms={30} />
				<Typing.Delay ms={300} />
				<br />
				<div className="caret">Hello World!</div>
				<br />
				<Typing.Delay ms={600} />
				<div className="caret">My name is Yasa</div>
				<Typing.Delay ms={600} />
				<div className="caret">I <span role="img" aria-label="love">❤️</span> to bring applications to the web!</div>
				<br />
				<Typing.Delay ms={600} />
				<div className="caret">You can find out more about me by visiting <Link to="/about">About</Link>.</div>
				<br />
				<Typing.Delay ms={800} />
				<div className="caret">If you rather want to see projects I worked on, visit <Link to="/projects">Projects</Link>.</div>
			</Typing>
		</div>
	</div>
)