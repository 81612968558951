import React from "react";
//import {Link} from 'react-router-dom'

import "./../css/Projects.css";
import Title from "./../Title";

import SpaceExplorerImg from "./../images/projects/spaceexplorer.png";
import WaveJumpImg from "./../images/projects/wavejump.png";
import WebMusicImg from "./../images/projects/webmusic.png";
import KinectScanImg from "./../images/projects/kinectscan.png";
import MVPTimerImg from "./../images/projects/mvptimer.png";

const projects = [
  {
    name: "Space Explorer",
    image: SpaceExplorerImg,
    description:
      "A university group-project that invites users to explore our solarsystem. I accounted for the front-end side of things which involved the rendering with three.js as well as finding methods to effectively navigate a large space like this.",
    url: "https://misc.kisaro.de/spaceexplorer/"
  },
  {
    name: "WebMusic",
    image: WebMusicImg,
    description:
      "WebMusic is a fun little project that attempts to mimic a media player, despite the name it is also able to play videos. Due to limitations in most browsers, this is limited to the mp3, wav, ogg, webm and mp4 formats.",
    url: "https://misc.kisaro.de/webmusic/"
  },
  {
    name: "Wave Jump",
    image: WaveJumpImg,
    description:
      'An endless scrolling game with the task to dodge walls and collect mushrooms! The game has been created in 2.5 days during the <a href="https://globalgamejam.org/" target="_blank" rel="noopener noreferrer">Global Game Jam</a> 2017. The music has been made by <a href="https://btopp.itch.io/" target="_blank" rel="noopener noreferrer">Niklas Bachmann</a>.',
    url: "https://misc.kisaro.de/wavejump/"
  },
  {
    name: "MVP Timer",
    image: MVPTimerImg,
    description:
      "A small tool to notify when a boss monster (MVP) respawns in the game Ragnarok Online, based on the last time it has been killed.",
    url: "https://misc.kisaro.de/mvp/"
  },
  {
    name: "Kinect Scan",
    image: KinectScanImg,
    description:
      "A small experiment from my bachelor thesis with the goal to visualize and explore 3D data scanned via a kinect. Mobile users beware of a rather large file size. Initial rendering takes a couple seconds even on a modern desktop PC.",
    url: "https://misc.kisaro.de/kinectfun/"
  }
];

export default () => (
  <div>
    <Title>Projects</Title>
    <div className="wrapper" id="projects">
      {projects.map((project, index) => (
        <div key={`project-${index}`}>
          <div
            className="img"
            style={{ backgroundImage: `url(${project.image})` }}
          />
          <h2>{project.name}</h2>
          <p dangerouslySetInnerHTML={{ __html: project.description }} />
          <a
            href={project.url}
            target="_blank"
            rel="noopener noreferrer"
            className="visitlink"
          >
            Visit Project
          </a>
        </div>
      ))}
    </div>
  </div>
);
