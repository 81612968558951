import React from "react";

import "./../css/About.css";
import Title from "./../Title";

import moi from "./../moi.png";

const getCurrentAge = (_) => {
  const diff = new Date().getTime() - new Date("1990-06-11T00:00:00").getTime();
  return new Date(diff).getUTCFullYear() - 1970;
};

export default () => (
  <div>
    <Title>About</Title>
    <div className="wrapper">
      <header>
        <div id="vCard" className="card">
          <img src={moi} alt="Yasa" />
          <h2>Yasa Yener</h2>
          <p>
            Age: {getCurrentAge()}
            <br />
            Location: Cologne, Germany
            <br />
            Speaks: German (native), English (fluent)
            <br />
            Occupation: Software Engineer
          </p>
          <br className="clear" />
        </div>

        <div id="vita" className="card">
          <h2>Education</h2>
          <ul id="vitaList">
            <li>
              <span>2015-2018</span>{" "}
              <a href="https://www.th-koeln.de/studium/informatik--computer-science-master_4152.php">
                Computer Science - Software Engineering
              </a>{" "}
              (Master of Science) at TH Köln
            </li>
            <li>
              <span>2010-2015</span>{" "}
              <a href="https://www.th-koeln.de/studium/medieninformatik-bachelor_2379.php">
                Medieninformatik
              </a>{" "}
              (Bachelor of Science) at FH Köln
            </li>
            {/*<li><span>2009-2010</span> Zivildienst</li>*/}
            <li>
              <span>2006-2009</span>{" "}
              <a href="http://www.gso-koeln.de/index.php/bildungsgaenge/informationstechnische-assistenten/berufsausbildung-und-fachhochschulreife">
                State-certified IT assistant
              </a>{" "}
              at Georg-Simon-Ohm Berufskolleg
            </li>
            <li>
              <span>1996-2006</span> High school (Realschule)
            </li>
          </ul>
        </div>
      </header>

      <div className="card" style={{ marginTop: "20px" }}>
        <h2>Experience</h2>

        <div className="experienceItem">
          <h3>
            Software Engineer at{" "}
            <a href="https://www.avenga.com/de/">Avenga Germany</a>
          </h3>
          <h4>2+ Years (2018 - now)</h4>
          <ul>
            <li>
              Developing interactive web applications with{" "}
              <a href="https://reactjs.org/">react</a>,{" "}
              <a href="https://www.typescriptlang.org/">TypeScript</a> and{" "}
              <a href="https://sass-lang.com/">SCSS</a>
            </li>
            <li>
              Backend development using <a href="https://nestjs.com/">NestJS</a>{" "}
              and <a href="https://typeorm.io/">TypeORM</a>
            </li>
            <li>Basic frontend-/backend-architecture and API design</li>
            <li>
              Working with vendor APIs using REST and{" "}
              <a href="https://graphql.org/">GraphQL</a>
            </li>
            <li>Refinement of technical requirements and tasks</li>
            <li>Coaching and working with trainees</li>
            <li>Working in an international developer team</li>
          </ul>
        </div>
        <div className="experienceItem">
          <h3>
            Research Assistant at{" "}
            <a href="http://www.geographie.uni-koeln.de">
              University of Cologne, Institute of Geography
            </a>
          </h3>
          <h4>6 Years (2013 - 2019)</h4>
          <ul>
            <li>
              Development of a progressive web app using{" "}
              <a href="https://reactjs.org/">react</a> and{" "}
              <a href="https://react-static.js.org/">react-static</a>
            </li>
            <li>Frontend development using HTML5, CSS3 and JavaScript</li>
            <li>
              Development using frameworks like{" "}
              <a href="http://jquery.com/">jquery</a>,{" "}
              <a href="http://getbootstrap.com/">bootstrap</a> and{" "}
              <a href="http://angularjs.org/">angular</a>
            </li>
            <li>
              Building a CI-Pipeline with{" "}
              <a href="https://about.gitlab.com/">gitlab</a>,{" "}
              <a href="https://jenkins-ci.org/">jenkins</a> und{" "}
              <a href="https://phpunit.de/">PHPUnit</a>- as well as{" "}
              <a href="http://www.seleniumhq.org/">selenium</a>-tests via{" "}
              <a href="http://behat.org/">behat</a>
            </li>
            <li>Backend development using PHP</li>
            <li>
              Managing <a href="http://typo3.org/">Typo3</a> and developing
              extensions using extbase and fluid
            </li>
            <li>
              Consuming the <a href="http://ckan.org/">CKAN</a>-API
            </li>
            <li>
              Administrating and distributing software in computing labs using{" "}
              <a href="http://www.opsi.org/en">opsi</a>
            </li>
          </ul>
        </div>
        <div className="experienceItem">
          <h3>
            Student Research Assistant for the field of algorithms and
            programming (Algorithmen der Programmierung 1) at{" "}
            <a href="http://www.gm.fh-koeln.de">FH Köln</a>
          </h3>
          <h4>6 Months (2011 - 2012)</h4>
          <ul>
            <li>Mentoring students in the topics of c- and java-programming</li>
            <li>Consulting in technical or functional challenges</li>
            <li>Assessment/approval of assignments</li>
          </ul>
        </div>
        <div className="experienceItem">
          <h3>Internship at pmm - projekt media management</h3>
          <h4>8 Weeks (2008)</h4>
          <ul>
            <li>Frontend development in HTML, CSS and JavaScript</li>
            <li>Graphic processing using Adobe Photoshop</li>
            <li>Search engine optimization</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
);
