import React from 'react'
//import {Link} from 'react-router-dom'
import animated404 from './../404.gif'

export default () => (
	<div style={{
		backgroundImage: `url(${animated404})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		height: 'calc(100vh - 62px)'
	}}>
		<div style={{
			backgroundColor: 'rgba(0,0,0,0.5)',
			color: '#fff',
			fontSize: '4em',
			textAlign: 'center',
			height: '100%',
			padding: '40px',
			boxSizing: 'border-box'
		}}>
			Uh Oh!<br />
			Error 404, page not found :(
		</div>
	</div>
)