import React from 'react'
import Title from './../Title'

import './../css/Imprint.css'

export default _ => (
	<div>
		<Title>Imprint</Title>
		<div className="wrapper">
			<div className="card">
				<h2>Author</h2>
				Yasa Yener<br />
				<div id="address"></div>
				<div id="address2"></div>
				<br />
				<div id="email"></div>
			</div>
		</div>
	</div>
)