import React, { Component } from 'react'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'

import './App.css'
import Navigation from './Navigation'
import Footer from './Footer'

import Home from './routes/Home'
import About from './routes/About'
import Projects from './routes/Projects'
import Imprint from './routes/Imprint'
import route404 from './routes/404'


class App extends Component {
	render() {
		return (
			<Router basename="/">
				<div className="App">
					<Navigation />
					<Switch>
						<Route path="/" exact component={Home} />
						<Route path="/about" component={About} />
						<Route path="/projects" component={Projects} />
						<Route path="/imprint" component={Imprint} />
						<Route component={route404} />
					</Switch>
					<Footer />
				</div>
			</Router>
		);
	}
}

export default App;
