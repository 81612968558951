import React from 'react'
import {NavLink} from 'react-router-dom'
import './css/Navigation.css'

import logo from './logo.png'

export default class Navigation extends React.Component {
	render() {
		return (
			<nav id="navigation">
				<ul>
					<li className="logo"><NavLink to="/" exact={true}><img src={logo} alt="Home" /></NavLink></li>
					<li><NavLink to="/about" activeClassName="active">About</NavLink></li>
					<li><NavLink to="/projects" activeClassName="active">Projects</NavLink></li>
				</ul>
			</nav>
		)
	}
}